import React from "react"
import * as styles from "./NoMatch.module.css"
import { withPrefix } from "gatsby"

export default function NoMatch() {
  return (
    <div className={styles.dialog}>
      <img alt="Econify 404 not found" src={withPrefix("/images/404.svg")} />
      <h1>404</h1>
      <p>
        We didn&apos;t find what you are looking for → <a href="/">home</a>
      </p>
    </div>
  )
}
